import * as React from "react"
import { Header } from "../../layout/header"
import '../../index.scss';
//import './sites/all/themes/tickets/js/estacas/form_tickets_rd.js';
import '../../sites/all/themes/tickets/js/estacas/map.js';
import '../../sites/all/themes/tickets/js/estacas/cards_rd'
//import './sites/all/themes/tickets/js/estacas/stickyfloat'
import '../../sites/all/themes/tickets/js/estacas/eventos'
import '../../sites/all/themes/tickets/js/estacas/form_tickets_rd'
//import './stylesheets/tickets.css'
//import './sites/all/themes/tickets/js/estacas/header_rd';
import '../../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../../sites/all/themes/tickets/js/estacas/header_rd';
import {PlantillaReserva}  from "../../layout/plantilla_reserva";
import PlantillaReglamento from "../../layout/plantilla_reglamento";
import PlantillaListaBlog from "../../layout/plantilla_lista_blog";

// markup
const BlogListaPage = () => {
  return (
    <body className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
    <PlantillaListaBlog />
    </body>
  )
}

export default BlogListaPage
