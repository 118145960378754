import React from "react";

  
const CardListaBlog = ({data,id}) => {
 //console.log(data)
    
  return (
   <div className={'block bloqueDispoBlog me-md-3 mb-3 me-lg-3 mt-1 mb-5'} >
<div className={'row mx-1'}>
    <div className={'  col-md-4  p-0'} style={{position:'relative'}}>
        <img style={{borderRadius: '20px', width: '100%',height: '240px',objectFit:'cover'}} src={data.imagen}/>
    </div>
<div className={'col-md-8  ps-md-4 p-0'}>
    <h1 className="mt-lg-0 mt-3 mb-2 tituloCardBlogLista">{data.titulo}</h1>
    <p className="labelNormalGris">{data.fecha} por <span>{data.nombre}</span></p>
    <div className="heigParrafo" >
    <p className="labelNormal ocultar">{data.resumen}</p>

    </div>
    <a href={data.url} className={' links d-flex justify-content-center justify-content-md-start'}>Leer Más</a>

</div>


</div>
  
</div>
  );
};



export default CardListaBlog;