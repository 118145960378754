import React ,{useEffect,useState}from "react";
import Banner from "../components/banner";

import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";

import $ from 'jquery';

import BlogsCards from "../components/blogs_cards";

import BannerMobile from "../components/banner_mobile";

import CardContacto from "../components/card_contacto";
import CardContactoMobile from "../components/card_contacto_mobile";
import * as app from "../fetch/fetchApi";


const PlantillaReglamento = () => {
  const isBrowser = typeof window !== "undefined"
  let width;
  /**
   let datos = this.state.data;
          let datosInstagram = this.state.dataInstagram;
          let datosEventos = this.state.dataEvento
          let datosMenu = this.state.dataMenu
          let datosNodo = this.state.dataNodo
          let datosComplementa = this.state.dataComplementa
          let datosMenuFooter = this.state.dataMenuFooter
          let datosPrecios = this.state.dataPrecio
          let datosBlog=this.state.dataBlog
          let datosTarifas = this.state.dataTarifas
   */

  
  const [datosMenu, setDataMenu] = useState({})
  
  useEffect(() => {
    // get data from GitHub api
   
    fetch(app.url+`/node-api/m22_item_menu/main-menu`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenu(resultData)
      })
       // set data for the number of stars
  }, [])
  const [datosNodo, setDataNodo] = useState([])
  const [datosPrecios, setDataPrecios] = useState([])

  useEffect(() => {
    
    // get data from GitHub api
    if (isBrowser) {
    console.log(window.location.pathname)
let id = window.location.pathname
id = id.substring(1);
var data={'path':id}
console.log(id)

fetch(app.url+"/node-api/m22_get_contenido_node/"+id,{
  method:'GET',
  headers:{'Content-type':'application/json'},
})
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataNodo(resultData)
        
      })
       // set data for the number of stars
    }
  }, [])
  const [datosComplementa, setDataComplementa] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/views/prueba_get_interior_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataComplementa(resultData)
      })
       // set data for the number of stars
  }, [])
  const [datosMenuFooter, setDataMenuFooter] = useState({})
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenuFooter(resultData)
      })
       // set data for the number of stars
  }, [])
 

  const [datosBlogCard, setDataBlogCard] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/views/prueba_get_card_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBlogCard(resultData)
      })
       // set data for the number of stars
  }, [])
  
  const [datosBlog, setDataBlog] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/views/prueba_get_interior_blog?display_id=services_1&format_output=0&args[0]=2157`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBlog(resultData)
      })
       // set data for the number of stars
  }, [])

  
  const [datosReglamento, setDataReglamento] = useState([])

  useEffect(() => {
    
    // get data from GitHub api
    if (isBrowser) {
    console.log(window.location.pathname)
let id = window.location.pathname
id = id.substring(1);
id= id.replace('/','')
var data={'path':id}
    console.log(JSON.stringify( data,null,4))
fetch(app.url+"/node-api/m22_get_content_node/",{
  method:'POST',
  headers:{'Content-type':'application/json'},
  body:JSON.stringify( data,null,4)
})
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataReglamento(resultData)
      })
       // set data for the number of stars
  }}, [])
  



  if (isBrowser) {
 width= window.innerWidth 
console.log(width) 
$(document).ready(function () {
  const nodes = document.getElementsByTagName("span");
for (let index = 0; index < nodes.length; index++) {
console.log(nodes[index].style.whiteSpace) 
if (nodes[index].style.whiteSpace==="pre") {
  nodes[index].style.whiteSpace="unset"
} 
}
})
  }
  var contenido = datosBlog;
  
return (
    
    <div>
              { width <1100?<Banner data={datosMenu}/>:<BannerMobile data={datosMenu} />
}
<div className="row px-2 px-md-3 px-xl-2 justify-content-between removeMax " style={{marginTop:'58px'}}>
    <div className="col-xl-7 mt-md-3 mt-0 col-12">

{datosReglamento!==undefined?<div className="tituloInteres margenTop">{datosReglamento.title}</div>:''}
<div>
{datosReglamento.body!==undefined?<div className="col-12 corte" style={{overflowX:'hidden'}} dangerouslySetInnerHTML={{__html: datosReglamento.body.und[0].value}} />:'' }
</div>

        </div>
        <div className="col-xl-3 col-12 mt-xl-5 mb-5 mt-0 ">
        <div className="d-block d-md-none d-xl-block mt-5">
          <CardContacto />
          </div>
          <div className="mt-0 d-none d-md-block d-xl-none">
          <CardContactoMobile />
          </div>

        </div>

</div>
{datosBlogCard.length!==0?<BlogsCards data={datosBlogCard} />:''}


            
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter data={datosMenuFooter}/>

            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
  );
};

export default PlantillaReglamento;